import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

@Component({
  selector: 'app-asp-dynamic-popup',
  templateUrl: './asp-dynamic-popup.component.html',
  styleUrls: ['./asp-dynamic-popup.component.scss']
})
export class AspDynamicPopupComponent implements OnInit {

  constructor(
    private router: Router,
    private bottomSheetRef: MatBottomSheetRef<AspDynamicPopupComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
 
  ) { }

  ngOnInit(): void {
  }
  closeSheet(): void {
    this.bottomSheetRef.dismiss();
  }
  navigate(){
  this.router.navigate(['home']);
  this.closeSheet();
  }
}
