<div class="container">
  <div class="my-request">

    <!-- Job Card Sidebar (Find My Car Sidebar) -->
    <div class="overlay" [class.visible]="isVisible" (click)="closeSidebar(false)"></div>
    <div class="job-card-sidebar sidebar" *ngIf="isVisible" (click)="$event.stopPropagation()">
      <div class="sidebar-header" (click)="onCreateJobCardClick()">
        <h2>Create Job Card</h2>
        <button class="close-sidebar" (click)="closeSidebar(false)">×</button>
      </div>
    
      <form class="container" [formGroup]="vehicleForm1" (ngSubmit)="onRegDecodeSubmit()">
        <div *ngIf="!bookingId">
          <label for="name" style="font-weight: bolder;">Enter your name <span class="required">*</span></label>
          <div class="input-group">
            <input id="name" type="text" placeholder="Enter your name" formControlName="name">
           <!-- <TextField id="outlined-basic" label="Outlined" variant="outlined" /> -->
          </div>
          <div *ngIf="(vehicleForm1.get('name').invalid && vehicleForm1.get('name').touched || formSubmitted) || (vehicleForm1.get('name').invalid && formSubmitted)" style="color: red;">
            Enter Valid Name
          </div>
        </div>
    
        <div *ngIf="!bookingId">
          <label for="phoneNumber" style="font-weight: bolder;">Enter your Mobile Number <span class="required">*</span></label>
          <div class="input-group">
            <input id="phoneNumber" type="text" placeholder="Enter your Mobile Number" formControlName="phoneNumber" pattern="[0-9]*" maxlength="10">
          </div>
          <div *ngIf="(vehicleForm1.get('phoneNumber').invalid && vehicleForm1.get('phoneNumber').touched || formSubmitted) || (vehicleForm1.get('phoneNumber').invalid && formSubmitted)" style="color: red;">
            Enter Valid Mobile Number
          </div>
        </div>
    
        <label for="odometer" style="font-weight: bolder;">Enter Odometer Reading <span class="required">*</span></label>
        <div class="input-group">
          <span class="small-img"><img src="assets/images/my-job-card-images/carkm.png" class="input-icon" alt="Car KM"> KM</span>
          <input id="odometer" type="text" placeholder="10000" formControlName="odometer">
        </div>

        <div *ngIf="(vehicleForm1.get('odometer').invalid && vehicleForm1.get('odometer').touched || formSubmitted) || (vehicleForm1.get('odometer').invalid && formSubmitted)" style="color: red;">
          Enter Valid Odometer Reading
        </div>
    
        <label for="regNo" style="font-weight: bolder; ">We recommend to enter car number</label>
        <div class="input-group">
          <span class="small-img"><img src="assets/images/my-job-card-images/circle.png" class="input-icon" alt="Car Number"> IND</span>
          <input id="regNo" placeholder="KA 04 JA54**" maxlength="12" style="font-weight: 800;" formControlName="regNo">
        </div>
        <div *ngIf="formSubmitted && vehicleForm1.invalid" style="color: red; margin-top: 10px;">
          Please fill out all mandatory fields before proceeding.
        </div>
    
        <input type="hidden" formControlName="rowKey">
        <input type="hidden" formControlName="variantKey">
    
        <ul class="info-list">
          <li><img src="assets/images/my-job-card-images/ic_check.png" class="circle-img" alt="Check Circle">
            <span class="get">Get accurate details</span>
          </li>
          <li><img src="assets/images/my-job-card-images/ic_check.png" class="circle-img" alt="Check Circle">
            <span class="get">Get 2X faster results</span>
          </li>
        </ul>
    
        <button type="submit" mat-raised-button color="accent" class="submit-btn" aria-label="VEHICLE"
        [disabled]="vehicleForm1.invalid">
          FIND MY CAR
        </button>
        <p class="manual-selection" (click)="redirectToManualSelection()" [class.disabled]="!isManualSelectionValid()">
          Or select car manually</p>
      </form>
    </div>
    
    <!-- Confirmation Sidebar (After Find My Car) -->
    <div class="overlay" [class.visible]="isConfirmationSidebarOpen" (click)="closeSidebar(false)"></div>
    <div class="confirmation-sidebar" *ngIf="isConfirmationSidebarOpen" (click)="$event.stopPropagation()">
      <div class="confirmation-content">
        <div class="header-container">
          <mat-icon class="back-arrow" (click)="toggleConfirmationSidebar()">arrow_back</mat-icon>
          <mat-icon style="color: #144579;">directions_car</mat-icon>
        </div>
        <h3>Confirm your car details</h3>
        <div class="details-item">
          <img src="assets/images/my-job-card-images/ic_check.png" alt="ic_check" class="circle-img">
          <div class="label">Make:</div>
          <div class="value">{{ jobCard.make }}</div>
        </div>
        <div class="details-item">
          <img src="assets/images/my-job-card-images/ic_check.png" alt="ic_check">
          <div class="label">Model:</div>
          <div class="value">{{ jobCard.model }}</div>
        </div>
        <div class="details-item">
          <img src="assets/images/my-job-card-images/ic_check.png" alt="ic_check">
          <div class="label">Variant:</div>
          <div class="value">{{ jobCard.variant }}</div>
        </div>
        <div class="details-item">
          <img src="assets/images/my-job-card-images/ic_check.png" alt="ic_check">
          <div class="label">MFG Year:</div>
          <div class="value">{{ jobCard.mfgYear }}</div>
        </div>
        <div class="details-item">
          <img src="assets/images/my-job-card-images/ic_check.png" alt="ic_check">
          <div class="label">Fuel:</div>
          <div class="value">{{ jobCard.fuelType }}</div>
        </div>
        <div class="details-item">
          <img src="assets/images/my-job-card-images/ic_check.png" alt="ic_check">
          <div class="label">VIN:</div>
          <div class="value">{{ jobCard.vin }}</div>
        </div>
        <button class="confirm-btn" (click)="confirmCarDetails()">Confirm</button>
        <button class="change-car-btn" (click)="redirectToManualSelection()">Change Car</button>
      </div>
    </div>

    <!-- Job Card Created Pop-up Modal -->
    <div *ngIf="isJobCardCreatedPopupVisible" class="modal-overlay">
      <div class="modal-content">
        <div class="modal-header">
          <img src="{{successIcon}}" alt="Success Icon" class="success-icon">
          <h2>Job Card Created</h2>
          <button class="close-modal" (click)="closeJobCardPopup()">×</button>
        </div>
        <div class="modal-body">
          <p>Job Card ID: <span class="job-card-id">{{jobCardId}}</span></p>
          <p><span class="createdAt1">{{date}}</span></p>
          <p>Let us deliver an awesome experience!</p>
        </div>
        <div class="modal-footer">
          <button class="share-job-card-btn" (click)="shareOnWhatsApp()">SHARE JOB CARD</button>
        </div>
      </div>
    </div>

  </div>
</div>
