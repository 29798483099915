<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
   
    <!-- <mat-progress-bar class="mt-8" *ngIf="loading" mode="indeterminate"></mat-progress-bar> -->
    <div class="payment mt-8 p-8">            
        <h2 class="payment-title">Select Payment</h2>

        <div class="payment-left">
            <div class="location-sec">
                <div class="payment-card"> 
                    <div class="location-icon"><mat-icon> location_on</mat-icon></div>
                    <div class="location-content">
                        <div class="location-title">Shipping Address</div>
                        <div class="location-details">
                            {{shippingAddress}}
                        </div>
                    </div>
                </div>
            </div> 
            <!-- {{walletData|json}} -->
            <!-- autoverse wallet -->
            <div class="wallet-sec" *ngIf="walletData?.wallet?.isWallet==true && walletData?.wallet?.walletAmount>0">
                <div class="wallet-card">
                    <div class="wallet-icon1" style="margin-left: 10px; color: #144579;">
                        <mat-icon>account_balance_wallet</mat-icon>
                    </div>
                    <div class="wallet-content">
                        <div class="wallet-title">Autoverse  Wallet</div>
                        <!-- <div class="wallet-balance">Available Balance: {{(walletData.wallet.walletAmount-walletAmountToUse) | number:'1.2-2'}}</div> -->
                        <div class="wallet-balance">
                            Available Balance: {{walletChecked ? (walletData.wallet.walletAmount - walletAmountToUse) : walletData.wallet.walletAmount | number:'1.2-2'}}
                        </div>
                    </div>
                    <div class="wallet-checkbox-last">
                        <input type="checkbox" [(checked)]="walletChecked" (change)="toggleWalletCheckbox()">
                    </div>
                </div>
            </div>



            <div class="payment-sec">
                <div class="payment-card"> 
                    <div class="payment-title">Mode of payment</div>
                    <mat-radio-group aria-label="Select an option">
                        <mat-radio-button value="1" (click)="getPaymentMode(1)">
                            <div class="cod-btn"> 
                                <mat-icon>account_balance_wallet</mat-icon>
                                <span>Cash On Delivery</span>
                            </div>
                        </mat-radio-button>
                        <mat-radio-button value="2" (click)="getPaymentMode(2)">
                            <div class="cod-btn"> 
                                <mat-icon>payment</mat-icon>
                                <span *ngIf="!prepayUpiDiscount">Pay Online</span>
                                <span *ngIf="prepayUpiDiscount">Pay by UPI</span>
                            </div>
                        </mat-radio-button>
                        <span *ngIf="prepayUpiDiscount && prepayUpiDiscount!=undefined && prepayUpiDiscount!= 'undefined'" class="online-payment-sub-heading">{{prepayUpiDiscount}}</span>
                    </mat-radio-group>

                </div>
            </div> 
        </div>
        
        <div class="payment-right">
            <div #target  class="price-details-sec" [@shakeit]="this.states['shake']" (@shakeit.done)="shakeEnd('shake', $event)">
                <div class="payment-card"> 
                    <div class="price-details-title">Price Details</div>
                    <div class="price-details">
                        <div class="price-details-left">Spares Total</div>
                        <div class="price-details-right">&#8377;{{cartSubTotal | number:'1.2-2'}}</div>
                    </div>
                     <div class="price-details" *ngIf="cartSubTotal> avoidShippingCharges">
                        <div class="price-details-left">Shipping Charges
                            <i style="cursor: pointer;" class="maticon" mat-raised-button matTooltip="Provides free Shipping for orders above ₹ 699" (click)="openBottomSheet()">
                                info_outline
                              </i>
                        </div>
                        
                        <div class="freetag">₹ 99
                            <span class="price-details-right"  style="color: #4caf50;">FREE</span>
                        </div>
                    </div> 
                    <div class="price-details" *ngIf="cartSubTotal <= avoidShippingCharges && shippingChargesApplicable">
                        <div class="price-details-left">Shipping Charges
                            <i style="cursor: pointer;" class="maticon" mat-raised-button matTooltip="Provides free Shipping for orders above ₹ 699" (click)="openBottomSheet()">
                                info_outline
                              </i>
                        </div>
                        
                            <span class="price-details-right"  >₹{{shippingCharges}}</span>
                    </div> 
                    <ng-template #bottomSheetTemplate >
                        <div class="bottom-sheet-content">
                            <i class="material-icons" (click)="closeBottomSheet()" style="float: left; cursor: pointer;">cancel</i>
                          <h3 style="font-weight: bold;">Shipping Charge</h3>
                          <p style="font-size: 13px;">Autoverse provides free Shipping for orders above ₹ 699</p>
                          <button mat-button class="custom-button" (click)="closeBottomSheet()">Okay, Got it</button>
                        </div>
                      </ng-template> 


                    <!-- Spares Discount -->
                    <div class="price-details" *ngIf="sparesDiscount>0">
                        <div class="price-details-left">Spares Discount</div>
                        <div class="price-details-right" id="discount">- &#8377;{{sparesDiscount | number:'1.2-2'}}</div>
                    </div>
                    

                    <!-- autoverse app wallet used -->
                     <div class="price-details" *ngIf="walletChecked && walletData?.wallet?.walletAmount>0">
                        <div class="price-details-left">Autoverse  Wallet Used: </div>
                        <div class="price-details-right" id="wallet">- {{ walletAmountToUse | number:'1.2-2'}}</div>
                     </div>


                    <!-- Coupon Discount -->
                    <!-- <div class="price-details" >
                        <div class="price-details-left">Coupon Discount</div>
                        <div class="price-details-right" id="coupon-discount">-100</div>
                    </div>   -->
                             
                    <!-- Shipping Charges -->
                    <div *ngIf="isShippingCharges=='true'" class="price-details" id="shipping-charges">
                        <div class="price-details-left">Shipping Charges <mat-icon (click)="shippingChargesBottomSheet()">info</mat-icon></div>
                        <div *ngIf="shippingChargesApplicable==true" class="price-details-right">&#8377;{{shippingCharges | number:'1.2-2'}}</div>
                        <div *ngIf="shippingChargesApplicable==false" class="price-details-right">
                            <span class="mrp-price">&#8377;{{shippingCharges | number:'1.2-2'}}</span>
                            <span id="free-shipping">FREE</span>
                        </div>
                    </div>  

                    <div class="price-details" id="total" *ngIf="walletChecked && walletAmountToUse && walletAmountToUse>0">
                        <div class="price-details-left">Total</div>
                        <div class="price-details-right">&#8377;{{(cartGrandTotal-walletAmountToUse) | number:'1.2-2'}}</div>
                    </div>

                    <div class="price-details" id="total" *ngIf="!walletChecked || !walletAmountToUse || walletAmountToUse<=0">
                        <div class="price-details-left">Total</div>
                        <div class="price-details-right">&#8377;{{(cartGrandTotal) | number:'1.2-2'}}</div>
                    </div>
                </div>
            </div>
        </div>

        

        
    </div>
    <div class="confirm-order-sec" *ngIf="paymentMethod && initiatedPayment!=true && shippingAddressId && billingAddressId">
        <div class="grand-total">&#8377;{{cartGrandTotal | number:'1.2-2'}}</div>
        <div class="view-details">
            <a (click)="scroll(target)">View Details</a>
        </div>
        <button class="confirm-order-btn" (click)="confirmOrder(paymentMethod)">{{paymentButtonText}}</button>
    </div>
</div>
<div *ngIf="loading">
    <div #dynamicContainers></div>
    </div>