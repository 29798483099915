import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import {AspVariantService} from './asp-variant.service';
import {AspLoginService} from '../asp-login/asp-login.service';
import { AspGarageSidebarComponent } from '../asp-garage-sidebar/asp-garage-sidebar.component';
import { AspLoadingComponent } from '../asp-loading/asp-loading.component';

@Component({
  selector: 'app-asp-variant',
  templateUrl: './asp-variant.component.html',
  styleUrls: ['./asp-variant.component.scss']
})
export class AspVariantComponent implements OnInit, AfterViewInit {

  loading:boolean = false;
  searchText:string = '';
  variantListPetrol:any = [];
  variantListDiesel:any = [];
  variantListCNG:any = [];
  openSearch:boolean = true;
  clearSearch:boolean = false;
  selectedMake: any;
  selectedModel: any;
  selectedYear: any;
  rowKey: any;
  spareSelectedGarageName: any;

  @ViewChild('dynamicContainers', { read: ViewContainerRef }) containers!: ViewContainerRef;

  constructor(
    private router: Router,
    private aspVariantService: AspVariantService,
    private aspLoginService: AspLoginService,
    private resolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
  ) { }
  
  ngOnInit(): void {
    this.loading = true;
    this.aspLoginService.checkLogin();

    if(localStorage.getItem('newMake'))
      this.selectedMake = localStorage.getItem('newMake');
    else
      this.selectedMake = localStorage.getItem('selectedMake');

    if(localStorage.getItem('newModel'))
      this.selectedModel = localStorage.getItem('newModel');
    else
      this.selectedModel = localStorage.getItem('selectedModel');

    if(localStorage.getItem('newYear'))
      this.selectedYear = localStorage.getItem('newYear');
    else
      this.selectedYear = localStorage.getItem('selectedYear');
      
    if(localStorage.getItem('newRowKey'))
      this.rowKey = localStorage.getItem('newRowKey');
    else
      this.rowKey = localStorage.getItem('rowKey');


    this.getVariantData(this.rowKey, this.selectedYear);   
     
  }
 
    ngAfterViewInit() {
  this.loadingMsg();
  this.cdr.detectChanges();
}

loadingMsg() {
  
  const factory = this.resolver.resolveComponentFactory(AspLoadingComponent);
  
 

  if (this.containers) {
    const componentRef = this.containers.createComponent(factory);
    

    const componentBInstance = componentRef.instance as AspLoadingComponent;
    componentBInstance.getLoadingMsg();
  } else {
    console.error('containers is undefined');
  }
}

  getVariantData(rowKey, year){
    this.aspVariantService.getVariants(rowKey, year).subscribe(
      result => {
        this.loading = false;
        let res = JSON.parse(JSON.stringify(result)); 

        Object.values(res.data).forEach(list => {
          // Petrol
          if(list['fuelType'] == 'Petrol'){
            this.variantListPetrol.push(list);  
          }

          // Diesel
          if(list['fuelType'] == 'Diesel'){
            this.variantListDiesel.push(list);  
          }

          // CNG
          if(list['fuelType'] == 'CNG'){
            this.variantListCNG.push(list);  
          }        
        });    
        // console.log(this.variantListCNG);    
      }
    );    
  }  

  // selectVariant(variantKey: any, variant: any, fuelType: any) {    
  //   variant = variant+' - '+fuelType;
  //   if(localStorage.getItem('selectedMake') && localStorage.getItem('selectedModel') && localStorage.getItem('selectedYear') && localStorage.getItem('variant') && localStorage.getItem('variantKey')){
  //     localStorage.setItem('newVariantKey', variantKey);
  //     localStorage.setItem('newVariant', variant);
  //   }else{
  //     localStorage.setItem('variantKey', variantKey);
  //     localStorage.setItem('variant', variant);
  //   }

  //   this.router.navigate(['spare-parts-list']);

  // }

  // selectVariant(variantKey: any, variant: any, fuelType: any): void {    
  //   variant = variant + ' - ' + fuelType;
  
  selectVariant(variantKey: any, variant: any, fuelType: any): void {    
   
    if (localStorage.getItem('jobcard') === '1') {
        // Do not add fuelType to the variant if jobcard is 1
        localStorage.setItem('variant', variant); 
        localStorage.setItem('fuelType',fuelType);
    } else {
        // Add fuelType to the variant if jobcard is not 1
        // variant = variant + ' - ' + fuelType;
        variant = variant;
        localStorage.setItem('variant', variant);  // Store variant with fuelType
    }
    // Store variantKey and variant in localStorage
    if (localStorage.getItem('selectedMake') && localStorage.getItem('selectedModel') && localStorage.getItem('selectedYear') && localStorage.getItem('variant') && localStorage.getItem('variantKey')) {
      localStorage.setItem('newVariantKey', variantKey);
      localStorage.setItem('newVariant', variant);
    } else {
      localStorage.setItem('variantKey', variantKey);
      localStorage.setItem('variant', variant);
    }
  
    // Check if 'jobcard' is set to 1 in localStorage
    if (localStorage.getItem('jobcard') === '1') {
      // Navigate to my-job-cards page
      this.router.navigate(['/my-job-cards']);
    } else {
      // Navigate to spare-parts-list page
      localStorage.setItem('selectedSparesList', JSON.stringify({"name": localStorage.getItem('selectedMake') +' '+ localStorage.getItem('selectedModel'), "type": 'allSpares', "imageUrl": "","key":""}));
      this.router.navigate(['/spare-parts-list']);
    }
  }
  

  searchFilterType(){
    this.openSearch = false;
    this.clearSearch = true;
  }

  clearSearchFilter(){
    this.openSearch = true;
    this.clearSearch = false;
    this.searchText = '';
  }

  goToMake(){      
    this.router.navigate(['make']);
  }

  goToModel(){      
    this.router.navigate(['model']);
  }

  goToYear(){      
    this.router.navigate(['year']);
  }
    
}