import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {SearchSuggestionService} from 'app/shared/search-suggestion.service';

@Component({
  selector: 'app-asp-search',
  templateUrl: './asp-search.component.html',
  styleUrls: ['./asp-search.component.scss']
})
export class AspSearchComponent implements OnInit {
  isVisible1: boolean;
  isInputVisible = false;
  searchText = '';
  suggestions: string[] = [];
  selectedSuggestions: Set<string> = new Set<string>();

  constructor(
    public router: Router,
    private searchSuggestionService:SearchSuggestionService,
  ) { }

  ngOnInit(): void {
  }
  private clearLocalStorage(){
        sessionStorage.removeItem('sparePartsDetails');
        localStorage.removeItem('vehicleType');
        localStorage.removeItem('newMake');
        localStorage.removeItem('newModel');
        localStorage.removeItem('newYear');
        localStorage.removeItem('newVariant');
        localStorage.removeItem('newVariantKey');
        localStorage.removeItem('newRowKey');
        localStorage.removeItem('isShippingCharges');
        localStorage.removeItem('prepayUpiDiscount');
        // localStorage.removeItem('vehicleData');
        localStorage.removeItem('selectedModel');
        localStorage.removeItem('selectedYear');
        localStorage.removeItem('variant');
        localStorage.removeItem('selectedFuel');
        localStorage.removeItem('variantKey');
        localStorage.removeItem('rowKey');
        localStorage.removeItem('selectedSparesList');
        localStorage.removeItem('preSearchValue');
    };
    closeSearchbar(ishide: boolean) {
    this.isVisible1 = ishide;
    // if(this.isVisible1==false){
    
      // event.stopPropagation();
    // }

  }

  toggleSearch() {
    this.isInputVisible = !this.isInputVisible;
    if (this.isInputVisible) {
      setTimeout(() => {
        const input = document.querySelector('.SearchInput') as HTMLInputElement;
        if (input) {
          input.focus();
        }
      }, 0);
    }
  }

  clearSearch(event: Event) {
    this.searchText = '';
   event.stopPropagation();
    // this.searchText = ''; // Clear the search text
    this.isInputVisible = false; // Hide the input field if needed
    this.filterSuggestions();
  }

    
  filterSuggestions() {
    if (this.searchText && this.searchText.length >= 3) {
        let rowKey = localStorage.getItem('rowKey');
        
        let universal = false;
        let variantKey = localStorage.getItem('variantKey');
        
        this.searchSuggestionService.getSearchSuggestions(rowKey, this.searchText, universal, variantKey).subscribe(
            result => {


                this.suggestions = [];
                if (result['data'].partNumbers && result['data'].partNumbers.length > 0)
                    this.suggestions.push(...result['data'].partNumbers.map((value: string) => ({ value, type: 'partNumber' })));
                if (result['data'].brands && result['data'].brands.length > 0)
                    this.suggestions.push(...result['data'].brands.map((value: string) => ({ value, type: 'brand' })));
                if (result['data'].categories && result['data'].categories.length > 0)
                    this.suggestions.push(...result['data'].categories.map((value: string) => ({ value, type: 'category' })));
                if (result['data'].partNames && result['data'].partNames.length > 0)
                    this.suggestions.push(...result['data'].partNames.map((value: string) => ({ value, type: 'partName' })));


                // Sort suggestions based on relevance
                this.suggestions = this.sortSuggestionsByRelevance(this.suggestions, this.searchText);

            }
        );
    } else {
        this.suggestions = [];
    }
}

selectSuggestion(suggestion: { value: string, type: string }) {
  this.selectedSuggestions.add(suggestion.value);
  this.searchText = '';
  // this.filterSuggestions();
  this.isInputVisible = false;
  this.closeSearchbar(false);
  if (suggestion.type === 'partNumber') {
      this.clearLocalStorage();
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['product-details/part-number/' + suggestion.value]);
      });
  } else if (suggestion.type === 'category') {
      this.clearLocalStorage();
      localStorage.setItem('selectedSparesList', JSON.stringify({"name": suggestion.value, "type": "categorySpares", "imageUrl": ""}));
      
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/spare-parts-list']);
      });
  } else if (suggestion.type === 'brand') {
      this.clearLocalStorage();
      localStorage.setItem('selectedSparesList', JSON.stringify({"name": suggestion.value, "type": "brandSpares", "imageUrl": ""}));
      
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/spare-parts-list']);
      });
  }else {            
      this.clearLocalStorage();
      localStorage.setItem("preSearchValue", suggestion.value);
      localStorage.setItem('selectedSparesList', JSON.stringify({"name": suggestion.value, "type": "allSpares", "imageUrl": ""}));
      
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/spare-parts-list']);
      });
  }
}

sortSuggestionsByRelevance(suggestions: string[], searchText: string): string[] {
  return suggestions?.sort((a, b) => {
      const relevanceA = this.getRelevance(a, searchText);
      const relevanceB = this.getRelevance(b, searchText);
      return relevanceB - relevanceA; // Higher relevance comes first
  });
}
getRelevance(suggestion: string, searchText: string): number {
  if (suggestion?.toString().toLowerCase() === searchText?.toLowerCase()) {
      return 3; // Exact match
  } else if (suggestion?.toString().toLowerCase().startsWith(searchText?.toLowerCase())) {
      return 2; // Starts with match
  } else if (suggestion?.toString().toLowerCase().includes(searchText?.toLowerCase())) {
      return 1; // Contains match
  } else {
      return 0; // No match
  }
}
isFirstSuggestion(suggestion: string, index: number): boolean {
  // Check if it's the first suggestion and if it matches preSearchValue
  let preSearchValue=localStorage.getItem('preSearchValue');
  return index === 0 && preSearchValue === suggestion['value'];
}

getHighlightedText(text: any, search: any): string {
  if (typeof text !== 'string' || typeof search !== 'string') {
      // Handle cases where text or search is not a string
      return text;
  }

  if (!search) {
      return text; // Return original text if no search term
  }

  const parts = text.split(new RegExp(`(${search})`, 'gi'));
  return parts.map(part => part.toLowerCase() === search.toLowerCase()
      ? `<span class="highlight">${part}</span>`
      : part
  ).join('');
}
clearSearchbar(event: Event) {
  this.searchText = '';
 event.stopPropagation();
  // this.searchText = ''; // Clear the search text
  this.isInputVisible = false; // Hide the input field if needed
  this.filterSuggestions();
  this.closeSearchbar(false);
}
}
