import { Component, OnInit, NgZone, ViewChild, ViewContainerRef, TemplateRef, ComponentFactoryResolver, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { trigger,state,style,transition,animate,keyframes } from    '@angular/animations';
import {AspLoginService} from '../asp-login/asp-login.service';
import {AspCartService} from './asp-cart.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
// import {MatBottomSheet} from '@angular/material/bottom-sheet';
// import { AspShippingChargesBottomSheetComponent } from "../asp-shipping-charges-bottom-sheet/asp-shipping-charges-bottom-sheet.component";
import { AspShippingChargesPopupComponent } from "../asp-shipping-charges-popup/asp-shipping-charges-popup.component";
import { AspCouponDiscountPopupComponent } from "../asp-coupon-discount-popup/asp-coupon-discount-popup.component";
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AspLoadingComponent } from '../asp-loading/asp-loading.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-asp-cart',
  templateUrl: './asp-cart.component.html',
  styleUrls: ['./asp-cart.component.scss'],
  animations: [
    trigger('shakeit', [
        state('shakestart', style({
            transform: 'scale(1)',
        })),
        state('shakeend', style({
            transform: 'scale(1)',
        })),
        transition('shakestart => shakeend', animate('1000ms ease-in',     keyframes([
          style({transform: 'translate3d(-1px, 0, 0)', offset: 0.1}),
          style({transform: 'translate3d(2px, 0, 0)', offset: 0.2}),
          style({transform: 'translate3d(-4px, 0, 0)', offset: 0.3}),
          style({transform: 'translate3d(4px, 0, 0)', offset: 0.4}),
          style({transform: 'translate3d(-4px, 0, 0)', offset: 0.5}),
          style({transform: 'translate3d(4px, 0, 0)', offset: 0.6}),
          style({transform: 'translate3d(-4px, 0, 0)', offset: 0.7}),
          style({transform: 'translate3d(2px, 0, 0)', offset: 0.8}),
          style({transform: 'translate3d(-1px, 0, 0)', offset: 0.9}),
        ]))),
  ])]
})

export class AspCartComponent implements OnInit, AfterViewInit {
  loading: boolean;  
  states = {};
  make: string = '';
  model: string = '';
  year: string = ''; 
  variant: string = '';
  makeImage:string = '';
  cartList = [];  
  cartCount = 0;
  cartSubTotal = 0;
  sparesDiscount = 0;
  cartGrandTotal = 0;
  shippingAddressId: any;
  newData = {};
  addedCart = [];
  spareSelectedGarageName: any;
  shippingChargesApplicable: any;
  shippingCharges: any;  
  vehicleType:any = localStorage.getItem('vehicleType');
  emptyImg:any = 'assets/images/no-cart.png';
  isShippingCharges:any;
  isMobile: boolean;
  avoidShippingCharges: any;


  constructor(
    private router: Router,    
    private zone: NgZone,
    public dialog: MatDialog,
    private aspLoginService: AspLoginService,
    private aspCartService: AspCartService,
    private toastr: ToastrService,    
    // private _bottomSheet: MatBottomSheet,
    private bottomSheet: MatBottomSheet,
    private resolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
  ) {
      this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    }); 
    this.states['shake'] = 'shakestart';
   }
   
  ngOnInit(): void {
    // this.loading = true;
    this.aspLoginService.checkLogin();
    this.getCart();
    this.make = localStorage.getItem('selectedMake');
    this.model = localStorage.getItem('selectedModel');
    this.year = localStorage.getItem('selectedYear');
    this.variant = localStorage.getItem('variant');

    if(this.make)
      this.makeImage = 'https://cube.getpitstop.com/assets/img/'+(this.make.toLowerCase()).replace(' ','')+'.png';
   
    this.isShippingCharges = localStorage.getItem("isShippingCharges");
  }
  @ViewChild('dynamicContainers', { read: ViewContainerRef }) containers!: ViewContainerRef;

  @ViewChild('bottomSheetTemplate') bottomSheetTemplate!: TemplateRef<any>;


  ngAfterViewInit() {
    this.loadingMsg();
    this.cdr.detectChanges();
  
  }
  
  loadingMsg() {
    
    const factory = this.resolver.resolveComponentFactory(AspLoadingComponent);
    
   
  
    if (this.containers) {
      const componentRef = this.containers.createComponent(factory);
      
  
      const componentBInstance = componentRef.instance as AspLoadingComponent;
      componentBInstance.getLoadingMsg();
    } else {
      console.error('containers is undefined');
    }
  }
  
  scroll(el: HTMLElement) {
    el.scrollIntoView();
    this.shakeMe('shake');
  }

  shakeMe(stateVar: string) {
    this.states[stateVar] = (this.states[stateVar] === 'shakestart' ? 'shakeend' : 'shakestart');
  }

  shakeEnd(stateVar: string, event) {
  this.states[stateVar] = 'shakeend';
  }
  
  removeItem(count, item){ 
    this.addedCart = [];
    let data;
    data = JSON.parse(sessionStorage.getItem('addedCart'))[0];
    if(count>1){
      let countval = count-1;
      
      data[item].cart_qty = countval;
      this.addedCart[0] = data;
      sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      this.getCart();
    }else{
      this.deleteItem(item);
      this.toastr.error(data[item].name+' removed from cart', 'Cart Item');
    }
  }


  addItem(count, item){
    let countval = count+1;
    
    this.addedCart = [];
    let data;
    data = JSON.parse(sessionStorage.getItem('addedCart'))[0];
    // if(countval<=data[item].spareStocks.quantity){
      data[item].cart_qty = countval;
      this.addedCart[0] = data;
      sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
      this.getCart();
    // }else{
    //   this.toastr.error('Only '+data[item].spareStocks.quantity+' unit available for this item', 'Error');
    // }
  }

  getCart(){
   
    if(sessionStorage.getItem('addedCart')){    
      let cartData;  
      this.cartList = [];
      this.cartCount = 0;
      this.cartSubTotal = 0;
      this.sparesDiscount = 0;
      cartData = JSON.parse(sessionStorage.getItem('addedCart'));
      Object.values(cartData[0]).forEach(cart => { 
        this.cartCount += cart['cart_qty'];
        
        this.cartSubTotal += (cart['mrp']*cart['cart_qty']);
        this.sparesDiscount += (cart['discount']*cart['cart_qty']);
        
        this.cartList.push(cart);
      });

      if(this.sparesDiscount>0){
        this.cartGrandTotal = (this.cartSubTotal - this.sparesDiscount);
      }else{
        this.cartGrandTotal = this.cartSubTotal;
      }

      if(this.isShippingCharges=='true'){
        this.getShippingCharges();
      }

      if(!this.isMobile){
        if(this.cartCount>0){
          (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = ''+this.cartCount+' items <div class="right-amount-carts">₹'+this.cartGrandTotal.toFixed(2)+'</div>';
        }else{
          (document.getElementsByClassName('cart_count_num')[0] as HTMLInputElement).innerHTML = 'My Cart';
        }
      }else{
        if(this.cartCount>0){
          (document.getElementsByClassName('cart_count_mobile')[0] as HTMLInputElement).innerHTML = '<div class="cart_count">'+this.cartCount+'</div>';
        }else{
          (document.getElementsByClassName('cart_count_mobile')[0] as HTMLInputElement).innerHTML = '';
        }
      }
      
      // console.log(this.cartList);
      
    }
    this.getShippingCharges()
  }

  deleteItem(item){
    this.newData = {};
    let cartData;
    cartData = JSON.parse(sessionStorage.getItem('addedCart'));
    Object.values(cartData[0]).forEach(cart => { 
      if(cart['key']!=item) {
        this.newData[cart['key']] = cart;
      }
    });
    
    this.addedCart[0] = this.newData;
    sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
    this.getCart();
    // console.log(this.addedCart);
    
  }

  addMore(){
    this.router.navigate(['spare-parts-list']);
  }

  payment(){
    this.router.navigate(['payment']);
  }

  // Shipping charges
  getShippingCharges(){    
    this.loading = true; 
    let garageKey = localStorage.getItem('garageKey');
    this.aspCartService.getShippingCharges(this.cartGrandTotal, garageKey).subscribe(
      result => {
        console.log("Response from getShippingCharges:", result);
        let res = JSON.parse(JSON.stringify(result)); 
        if(res.code==200){
          console.log("Shipping charges applicable:", res.data.shippingChargesApplicable);
          console.log("Shipping charges amount:", res.data.shippingCharges);
          this.shippingChargesApplicable = res.data.shippingChargesApplicable;
          this.shippingCharges = res.data.shippingCharges;
          this.avoidShippingCharges = res.data.avoidShippingCharges;
          this.loading = false;
          if(this.shippingChargesApplicable && this.cartSubTotal<=this.avoidShippingCharges){
            this.cartGrandTotal += this.shippingCharges;
          }
        } else {
          this.loading = false;
          console.log("Unexpected response code:", res.code);  // Log if the response code is not 200
        }
      }
    );
  }

  // shippingChargesBottomSheet(){
  //   this._bottomSheet.open(AspShippingChargesBottomSheetComponent);
  // }

  shippingChargesPopup(){
    this.zone.run(() => {
      this.dialog.open(AspShippingChargesPopupComponent);
    });
  }

  couponDiscountPopup(){
    this.zone.run(() => {
      this.dialog.open(AspCouponDiscountPopupComponent);
    });
  }

  browseParts(){
    // if(this.make && this.model && this.year && this.variant)
    //   this.router.navigate(['spare-parts-list']);
    // else
      this.router.navigate(['home']);
  }

  openBottomSheet(): void {
    this.bottomSheet.open(this.bottomSheetTemplate,{
     panelClass: 'custom-bottom-sheet1'
    }
    );
  }

  closeBottomSheet(): void {
    if (this.bottomSheet) {
      this.bottomSheet.dismiss();
    }
  }

}
