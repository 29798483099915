import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
// import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { ReplaceCartItemsService } from "../../../shared/replace-cart-items.service";
@Component({
  selector: 'app-asp-replace-cart-popup',
  templateUrl: './asp-replace-cart-popup.component.html',
  styleUrls: ['./asp-replace-cart-popup.component.scss']
})
export class AspReplaceCartPopupComponent implements OnInit {
  makeInfo: any;
  newMakeInfo: any;
  addedCart = [];
  newData = {};  
  cartList = [];
  cartCount = 0;
  cartSubTotal = 0;
  cartGrandTotal = 0;
  isCartCount:boolean = true;

  constructor(
    private router: Router,
    public dialog:MatDialogRef<AspReplaceCartPopupComponent>,
    // private _bottomSheetRef: MatBottomSheetRef<AspReplaceCartPopupComponent>,
    private replaceCartItemsService: ReplaceCartItemsService,
    ) { }

  ngOnInit(): void {
    if(localStorage.getItem('selectedMake') && localStorage.getItem('selectedModel') && localStorage.getItem('selectedYear') && localStorage.getItem('variant')){
      this.makeInfo = localStorage.getItem('selectedMake') +", "+ localStorage.getItem('selectedModel') +", "+ localStorage.getItem('selectedYear') +", "+ localStorage.getItem('variant');
    }

    if(localStorage.getItem('newMake') && localStorage.getItem('newModel') && localStorage.getItem('newYear') && localStorage.getItem('newVariant')){
      this.newMakeInfo = localStorage.getItem('newMake') +", "+ localStorage.getItem('newModel') +", "+ localStorage.getItem('newYear') +", "+ localStorage.getItem('newVariant');
    }
  }

  closePopup(){
    sessionStorage.removeItem('replaceCart');
    this.dialog.close();
  }

  // closePopup(event: MouseEvent){
  //   // this.dialog.close();
  //     this._bottomSheetRef.dismiss();
  //     event.preventDefault();
  // }

  replaceCart(){    
    let items = JSON.parse(sessionStorage.getItem('replaceCart'));
    this.addCart(items, 1);
    this.setNewMakeAsMake();
    this.closePopup();
    this.replaceCartItemsService.sendClickEvent();
  }

  addCart(items, count){
    this.addedCart = [];  
    items.cart_qty = count;
    let data = JSON.parse(sessionStorage.getItem('addedCart'));
    Object.values(data[0]).forEach(cart => { 
      if(cart['type']=="universal"){
        this.newData[cart['key']] = cart;
      }
    });
    this.newData[items.key] = items;
    this.addedCart.push(this.newData);
    // console.log(this.addedCart);
    sessionStorage.setItem('addedCart', JSON.stringify(this.addedCart));
    
  }

  setNewMakeAsMake(){
    localStorage.setItem('selectedMake', localStorage.getItem('newMake'));
    localStorage.setItem('selectedModel', localStorage.getItem('newModel'));
    localStorage.setItem('selectedYear', localStorage.getItem('newYear'));
    localStorage.setItem('variant', localStorage.getItem('newVariant'));
    localStorage.setItem('variantKey', localStorage.getItem('newVariantKey'));
    localStorage.setItem('rowKey', localStorage.getItem('newRowKey'));

    localStorage.removeItem('newMake');
    localStorage.removeItem('newModel');
    localStorage.removeItem('newYear');
    localStorage.removeItem('newVariant');
    localStorage.removeItem('newVariantKey');
    localStorage.removeItem('newRowKey');    
  }

}
