<div class="page-layout blank fullwidth p-12" fusePerfectScrollbar>
    <!-- <div class="mat-elevation-z8 mt-8 p-8 spares-list">             -->
        <h2 class="replace-cart-title">Replace cart item?</h2>

        <p>Your cart contains spares items for <span class="vehicle-info">{{makeInfo}}</span>. Do you want to discard the selection and add spares items for <span class="vehicle-info">{{newMakeInfo}}</span>?</p>

        <div>
            <button mat-raised-button class="close-popup" (click)="closePopup()">No</button> 

            <button mat-raised-button class="replace-cart" (click)="replaceCart()">Replace</button> 
        </div>
    <!-- </div> -->
</div>
