
<i class="arrow_back" (click)="scrollLeft()"  >arrow_back_ios</i>



<div class="bottom-sheet-container" >
        <div class="header">
        <h2 style="font-weight: bold;">{{dataRes.name }}</h2>
            <i class="material-icons" (click)="closeSheet()" style="cursor: pointer; margin-right: -52px;color: black;">close</i>
        </div>
        <h3 class="text" >Save Big With OES!</h3>
        <div class="compare-text">Compare Prices and Make Smart Choices</div>
    
        
        <div class="horizontal-list" #cardContainer>
           
            <div class="product-card"  *ngFor="let item of alternate" (click)="navigateToProductDetails(item)">
                    <div class="discount-badge">{{(item.discount / item.mrp* 100) | number: '.0'}} % OFF</div>
                    
                    <img src="{{item.imageUrl}}" alt="{{item.category}}" class="product-image" />
              
                    <div class="new-div">
                        <div class="product-info">
                        <h3>{{item.name}}</h3>
                        <div class="price-section">
                            <span class="discounted-price">₹{{item.sellingPrice}}</span>
                            <span class="original-price">₹{{item.mrp}}</span>
                            <div class="toolbar-separator"></div>
                            <span class="discount">{{(item.discount / item.mrp)* 100 | number: '1.0'}}%OFF</span>
                        </div>
                        <div class="ratings">
                            <span><i class="material-icons star-icon">star</i>5 Ratings</span>
                        </div>
                        <div class="partNumber-img">
                            <img src="../../../../assets/images/spares-list-images/unnamed (1).png" style="width: 18px;
                            height: 18px;" alt="PN">
                            <div class="spare-brand part-number">{{item.partNumber}}</div>
                        </div>

                        <div class="brand-name-img">
                            <img src="../../../../assets/images/spares-list-images/unnamed.png" style="width: 18pxpx;
                            height: 18px;" alt="B">
                            <div class="spare-brand brand-name">{{item.brand.name}}</div>
                        </div>

                        <div class="delivery-img">
                            <img src="../../../../assets/images/spares-list-images/unnamed (2).png"style="width: 18px;
                            height: 18px; " alt="T">
                            <div class="delivery"><span>{{ item.deliveryTime.replace('Delivered in ',' ' )}} </span></div>
                        </div>



                        <!-- <ng-template #onlyMRP>
                            <div class="spare-price-mrp">
                                &#8377;{{item.spareStocks.mrp}}
                            </div>
                        </ng-template> -->
                        </div>
                    </div>
               
            </div>
        </div>
          
  
      <!-- <button *ngIf="hasScroll" mat-icon-button (click)="scrollRight()">
        <mat-icon>chevron_right</mat-icon>
      </button> -->
 
  </div>
  
<i (click)="scrollRight()" class="arrow_forward" >arrow_forward_ios</i>
