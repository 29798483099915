import { Component, OnInit, NgZone, ViewContainerRef, ViewChild, ComponentFactoryResolver, TemplateRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { trigger,state,style,transition,animate,keyframes } from    '@angular/animations';
import {AspConfirmOrderPopupComponent} from '../asp-confirm-order-popup/asp-confirm-order-popup.component';
import {AspPaymentService} from './asp-payment.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { GlobalVariable } from '../../../main/globals';
import { AspSwitchGarageService } from '../asp-switch-garage/asp-switch-garage.service';
import { AspCartService } from "../asp-cart/asp-cart.service";
// import {MatBottomSheet} from '@angular/material/bottom-sheet';
// import { AspShippingChargesBottomSheetComponent } from "../asp-shipping-charges-bottom-sheet/asp-shipping-charges-bottom-sheet.component";
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AspLoadingComponent } from '../asp-loading/asp-loading.component';

declare var Razorpay: any;

@Component({
  selector: 'app-asp-payment',
  templateUrl: './asp-payment.component.html',
  styleUrls: ['./asp-payment.component.scss'],
  animations: [
    trigger('shakeit', [
        state('shakestart', style({
            transform: 'scale(1)',
        })),
        state('shakeend', style({
            transform: 'scale(1)',
        })),
        transition('shakestart => shakeend', animate('1000ms ease-in',     keyframes([
          style({transform: 'translate3d(-1px, 0, 0)', offset: 0.1}),
          style({transform: 'translate3d(2px, 0, 0)', offset: 0.2}),
          style({transform: 'translate3d(-4px, 0, 0)', offset: 0.3}),
          style({transform: 'translate3d(4px, 0, 0)', offset: 0.4}),
          style({transform: 'translate3d(-4px, 0, 0)', offset: 0.5}),
          style({transform: 'translate3d(4px, 0, 0)', offset: 0.6}),
          style({transform: 'translate3d(-4px, 0, 0)', offset: 0.7}),
          style({transform: 'translate3d(2px, 0, 0)', offset: 0.8}),
          style({transform: 'translate3d(-1px, 0, 0)', offset: 0.9}),
        ]))),
  ])]
})
export class AspPaymentComponent implements OnInit, AfterViewInit {
  loading: boolean = false;
  paymentMethod = '';
  paymentButtonText = '';
  states = {};
  shippingAddress = '';
  billingAddress = '';
  cartList = [];
  cartSubTotal = 0;
  sparesDiscount = 0;
  cartGrandTotal = 0;
  shippingAddressId: any;
  billingAddressId:any;
  orderKey: any = '';
  initiatedPayment:boolean = false;
  userDetails: any = [];
  spareSelectedGarageName: any;
  shippingChargesApplicable: any;
  shippingCharges: any;
  isShippingCharges: any;
  prepayUpiDiscount: any;
  autoverseLogo = '../../assets/images/logos/pitstop-white.png'
  walletChecked: boolean = false;
  walletData: any;
  walletAmountToUse: number = 0;
  avoidShippingCharges: any;
  

  constructor(    
    private router: Router,
    private zone: NgZone,
    public dialog: MatDialog,
    private aspPaymentService: AspPaymentService,
    private toastr: ToastrService,    
    private aspSwitchGarageService: AspSwitchGarageService,
    private aspCartService: AspCartService,
    // private _bottomSheet: MatBottomSheet,
    private resolver: ComponentFactoryResolver,
    private bottomSheet: MatBottomSheet,
    private cdr: ChangeDetectorRef,
    
  ) { 
    this.states['shake'] = 'shakestart';
  }
 
  ngOnInit(): void {    
    this.loading = true;
    this.getUser();
    this.getCart();    
    this.getUserDetails();
    this.getWalletData()
    this.isShippingCharges = localStorage.getItem("isShippingCharges");
    this.prepayUpiDiscount = localStorage.getItem("prepayUpiDiscount");
    // this.zone.run(() => {
    //   this.dialog.open(AspConfirmOrderPopupComponent);
    // });
  }
  @ViewChild('dynamicContainers', { read: ViewContainerRef }) containers!: ViewContainerRef;
  @ViewChild('bottomSheetTemplate') bottomSheetTemplate!: TemplateRef<any>;

  ngAfterViewInit() {
    this.loadingMsg();
    this.cdr.detectChanges();
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
    
  }
  
  
  loadingMsg() {
    
    const factory = this.resolver.resolveComponentFactory(AspLoadingComponent);
    
   
  
    if (this.containers) {
      const componentRef = this.containers.createComponent(factory);
      
  
      const componentBInstance = componentRef.instance as AspLoadingComponent;
      componentBInstance.getLoadingMsg();
    } else {
      console.error('containers is undefined');
    }
  }
  
  scroll(el: HTMLElement) {
    el.scrollIntoView();
    this.shakeMe('shake');
  }

  shakeMe(stateVar: string) {
    this.states[stateVar] = (this.states[stateVar] === 'shakestart' ? 'shakeend' : 'shakestart');
  }

  shakeEnd(stateVar: string, event) {
  this.states[stateVar] = 'shakeend';
  }

  getUser(){      
    this.loading = true;  
    let garageKey = localStorage.getItem('garageKey');
    this.aspPaymentService.getUserDetails(garageKey).subscribe(
      res => {
        // console.log(result);
        // let res = JSON.parse(JSON.stringify(result)); 
        if(res['code']==200){
          this.loading = false;
          Object.values(res['data']).forEach(address => { 
            if(address['addressType']=='billing'){
              this.billingAddress = address['address']+", "+address['city']+", "+address['state']+" "+address['pincode'];
              this.billingAddressId = address['id'];
            }
            
            if(address['addressType']=='shipping'){
              this.shippingAddress = address['address']+", "+address['city']+", "+address['state']+" "+address['pincode'];
              this.shippingAddressId = address['id'];
            }
          });
        }
      }
    );
  }

  getUserDetails(){ 
    let spareUserKey = localStorage.getItem('spareUserKey');
    this.aspSwitchGarageService.getUserDetails(spareUserKey).subscribe(
      result => {
        // console.log(result);
        let res = JSON.parse(JSON.stringify(result)); 
        if(res.code && res.code==200){
          this.userDetails['username'] = res.data.name;
          this.userDetails['user_mobile'] = res.data.mobile;
          this.userDetails['user_email'] = res.data.email;
        }else{
          this.toastr.success(res.message, 'Error');
        }
      }
    );
    // console.log(this.userDetails);
  }

  getPaymentMode(paymentMode){
    this.paymentMethod = paymentMode;
    if(paymentMode=="2"){
      this.paymentButtonText = 'Proceed To Pay';
    }else{
      this.paymentButtonText = 'Confirm Order';
    }
  }

  getCart(){
    if(sessionStorage.getItem('addedCart')){    
      let cartData;  
      this.cartList = [];
      this.cartSubTotal = 0;
      this.sparesDiscount = 0;
      cartData = JSON.parse(sessionStorage.getItem('addedCart'));
      Object.values(cartData[0]).forEach(cart => { 
        this.cartSubTotal += (cart['mrp']*cart['cart_qty']);
        this.sparesDiscount += (cart['discount']*cart['cart_qty']);
        
        this.cartList.push(cart);
      });

      if(this.sparesDiscount>0){
        this.cartGrandTotal = (this.cartSubTotal - this.sparesDiscount);
      }else{
        this.cartGrandTotal = this.cartSubTotal;
      }

      // if(this.walletAmountToUse>0){
      //   this.cartGrandTotal = (this.cartGrandTotal - this.walletAmountToUse)
      // }

      if(this.isShippingCharges=='true'){
        this.getShippingCharges();
      }
      // console.log(this.cartList);
    }
    this.getShippingCharges()
  }


  confirmOrder(paymentMethod){
    if(paymentMethod==1){
      this.codPayment();
    }else if(paymentMethod==2){
      this.createRazorPayOrder();
      // this.razorPayPayment();   
    }
    
  }

  // COD Payment - Pitstop
  codPayment(){
    this.initiatedPayment = true;
    let razorPayPaymentId = '';
    let orderKey = '';
    this.createOrder(razorPayPaymentId, orderKey, true);
  }

  // Create RazorPay order
  createRazorPayOrder(){    
    this.loading = true;    
    this.initiatedPayment = true;
    // this.cartGrandTotal = 1;

    if(this.walletChecked && this.walletAmountToUse>0){
      this.cartGrandTotal = (this.cartGrandTotal - this.walletAmountToUse);
    }
    
    let grandTotal = parseInt((this.cartGrandTotal*100).toFixed(0),10);
    let orderKey = '';
    let userKey = localStorage.getItem('spareUserKey');
    let userType = localStorage.getItem('userType');
    let garageKey = localStorage.getItem('garageKey');
    let agentKey = localStorage.getItem('agentKey');
    let orderType = 'spare';
    this.aspPaymentService.createRazorPayOrder(orderKey, userKey, garageKey, grandTotal, orderType, userType, agentKey).subscribe(
      result => {
        let res = JSON.parse(JSON.stringify(result)); 
        // console.log(res);
        if(res.code==200){
          let orderKey = res.data.orderKey;
          let razorpayOrder = JSON.parse(res.data.order);
          let razorPayOrderId = razorpayOrder.id;
          this.razorPayPayment(orderKey, razorPayOrderId);
        }else{
          this.router.navigate(['home']);
          this.toastr.error(res.message, 'Error');
        }
      }
    );
  }

  // Initiate Razorpay Payment
  razorPayPayment(orderKey, razorPayOrderId){
    this.orderKey = orderKey;
    let grandTotal = this.cartGrandTotal;
    const options = {
      key: GlobalVariable.RAZORPAY_ID,
      amount: parseInt((grandTotal*100).toFixed(0),10),
      name: 'Autoverse Mobility Pvt Ltd',
      image: this.autoverseLogo,
      description: 'Payment for spares purchase',
      currency: 'INR',
      order_id: razorPayOrderId,
      prefill: {
          name: this.userDetails['username'],
          email: this.userDetails['user_email'],
          contact: this.userDetails['user_mobile']
      },
      notes: {
          // order_id: orderId,
          type: 'spare',
          order_key: orderKey,
          // order_number: orderNumber,
          notes: 'Payment for spares purchase',
      },
      theme: {
          color: '#144579'
      },
      handler: this.paymentResponseHandler.bind(this)
  };
  // console.log(options);

  const rzp = new Razorpay(options);
  rzp.open();
  }

  // Razorpay success/failure handler
  paymentResponseHandler(response) { 
    if(response.razorpay_payment_id){      
      // this.dialog.open(AspConfirmOrderPopupComponent);
      let razorPayPaymentId = response.razorpay_payment_id;
      let razorPayOrderId = response.razorpay_order_id;
      let razorPaySignature = response.razorpay_signature;
      this.verifyPayment(razorPayPaymentId, razorPayOrderId, razorPaySignature, this.orderKey);
    }else{ 
      this.orderKey = '';
      this.toastr.error('Payment Failed', 'Error');
      // window.location.href = '/payment/failure/membership/' + this.membershipKey;
    }
  }

  // Verify Razorpay Payment
  verifyPayment(razorPayPaymentId, razorPayOrderId, razorPaySignature, orderKey){
    this.aspPaymentService.verifyPayment(razorPayPaymentId, razorPayOrderId, razorPaySignature, orderKey).subscribe(
      result => {
        let res = JSON.parse(JSON.stringify(result)); 
        // console.log(res);
        if(res.code==200){
          // console.log('Payment verified successfully!!');
          this.createOrder(razorPayPaymentId, orderKey, false);
        }else{
          this.router.navigate(['home']);
          this.toastr.error(res.message, 'Error');
        }
      }
    );
  }

  // Create Order - Pitstop (RazorPay Flow)
  createOrder(razorPayPaymentId, orderKey, type){
    this.loading = true;
    if(this.walletChecked && this.walletAmountToUse>0){
      this.cartGrandTotal = (this.cartGrandTotal - this.walletAmountToUse);
    }

    let grandTotal = parseInt((this.cartGrandTotal*100).toFixed(0),10);
    this.aspPaymentService.createOrder(this.shippingAddressId, this.billingAddressId, this.paymentMethod, razorPayPaymentId, orderKey, type, grandTotal, this.walletAmountToUse, this.walletChecked).subscribe(
      result => {
        let res = JSON.parse(JSON.stringify(result)); 
        // console.log(res);
        if(res.code==200){
          this.loading = false;
          sessionStorage.setItem('order', JSON.stringify(res.data));
          this.zone.run(() => {
            this.dialog.open(AspConfirmOrderPopupComponent);
          });
        }else{
          this.router.navigate(['home']);
          this.toastr.error(res.message, 'Error');
        }
      }
    );
  }
  toggleWalletCheckbox() {
    this.walletChecked = !this.walletChecked;
    // Add your wallet toggle logic here


    // If checkbox is checked, subtract wallet amount; otherwise, reset it
  if (this.walletChecked) {
    this.calculateWalletAmountToUse(); // Recalculate wallet amount to use
  } else {
    this.walletAmountToUse = 0; // Reset the wallet amount to use if unchecked
  }
  }
  // Bottom sheet open
  shippingChargesBottomSheet(){
    // this._bottomSheet.open(AspShippingChargesBottomSheetComponent);
  }

  // Shipping charges
  getShippingCharges(){    
    this.loading = true; 
    let garageKey = localStorage.getItem('garageKey');
    this.aspCartService.getShippingCharges(this.cartGrandTotal, garageKey).subscribe(
      result => {
        let res = JSON.parse(JSON.stringify(result)); 
        if(res.code==200){
          this.shippingChargesApplicable = res.data.shippingChargesApplicable;
          this.shippingCharges = res.data.shippingCharges;
          this.avoidShippingCharges = res.data.avoidShippingCharges;
          this.loading = false;
          if(this.shippingChargesApplicable && this.cartSubTotal<=this.avoidShippingCharges){
            this.cartGrandTotal = (this.cartGrandTotal+this.shippingCharges);
          }
        } else {
          console.log("Unexpected response code:", res.code);  // Log if the response code is not 200
        }
      }
    );
  }

  getWalletData() {
    const garageKey = localStorage.getItem('garageKey');
    const userKey = localStorage.getItem('spareUserKey');

    this.aspPaymentService.getWallet(garageKey,userKey).subscribe(
      res => {
        if (res['code'] === 200) {
          this.walletData = res['data']; 
          this.calculateWalletAmountToUse(); 
        } else {
          this.toastr.error(res['message'], 'Error');
        }
      },
      err => {
        this.toastr.error('Failed to load payment methods', 'Error');
      }
    );
  }
  calculateWalletAmountToUse() {
    if (this.walletData && this.walletData.wallet && this.walletData.wallet.isWallet) {
      const balance = this.walletData.wallet.walletAmount;
      const percentageValue = this.walletData.wallet.walletRule.value;
      const maximumValue = this.walletData.wallet.walletRule.maximum;

      // Calculate 10% of the wallet balance
      const calculatedAmount = (balance * percentageValue) / 100;

      // Ensure it doesn't exceed the maximum allowed value
      this.walletAmountToUse = Math.min(calculatedAmount, maximumValue);
    }
  }
  openBottomSheet(): void {
    this.bottomSheet.open(this.bottomSheetTemplate,{
     panelClass: 'custom-bottom-sheet1'
    }
    );
  }

  closeBottomSheet(): void {
    if (this.bottomSheet) {
      this.bottomSheet.dismiss();
    }
  }

}

